import theme from 'src/theme';
import { BudgetCategory, IProjectBudgetReview } from 'src/types/projects';

export const getChartReviewsData = (
    data: IProjectBudgetReview[],
    isTime: boolean = false
) => {
    const _aws: number[] = [];
    const coreTeam: number[] = [];
    const coreTeamHours: number[] = [];
    const techLeads: number[] = [];
    const techLeadHours: number[] = [];
    const _contributors: number[] = [];
    const _contributorHours: number[] = [];
    const other: number[] = [];
    const otherHours: number[] = [];
    const labels: string[] = [];
    const totalBudget: string[] = [];
    const budgetCurve: any[] = [];
    data.slice()
        .sort((a, b) =>
            Number(a.date.replace('-', '')) > Number(b.date.replace('-', ''))
                ? 1
                : -1
        )
        .forEach((item: IProjectBudgetReview) => {
            const { date, budget } = item;
            const aws_budget =
                budget.find((x) => x.name === BudgetCategory.AWS)?.amount || 0;
            _aws.push(aws_budget);
            const core_team_budget =
                budget.find((x) => x.name === BudgetCategory.CORE_TEAM)
                    ?.amount || 0;
            const core_team_hours =
                budget.find((x) => x.name === BudgetCategory.CORE_TEAM)
                    ?.hours || 0;
            coreTeam.push(core_team_budget);
            coreTeamHours.push(core_team_hours);
            const tech_leads_budget =
                budget.find((x) => x.name === BudgetCategory.TECH_LEAD)
                    ?.amount || 0;
            const tech_leads_hours =
                budget.find((x) => x.name === BudgetCategory.TECH_LEAD)
                    ?.hours || 0;
            const project_manager_budget =
                budget.find((x) => x.name === BudgetCategory.PROJECT_MANAGER)
                    ?.amount || 0;
            const project_manager_hours =
                budget.find((x) => x.name === BudgetCategory.PROJECT_MANAGER)
                    ?.hours || 0;
            const combined_tech_pm_team_budget =
                tech_leads_budget + project_manager_budget;
            const combined_tech_pm_team_hours =
                tech_leads_hours + project_manager_hours;
            techLeads.push(combined_tech_pm_team_budget);
            techLeadHours.push(combined_tech_pm_team_hours);
            const contributors_budget =
                budget.find((x) => x.name === BudgetCategory.CONTRIBUTOR)
                    ?.amount || 0;
            const contributors_hours =
                budget.find((x) => x.name === BudgetCategory.CONTRIBUTOR)
                    ?.hours || 0;
            _contributors.push(contributors_budget);
            _contributorHours.push(contributors_hours);
            const qa_budget =
                budget.find((x) => x.name === BudgetCategory.QA_CONTRIBUTOR)
                    ?.amount || 0;
            const qa_hours =
                budget.find((x) => x.name === BudgetCategory.QA_CONTRIBUTOR)
                    ?.hours || 0;
            const ui_ux_budget =
                budget.find((x) => x.name === BudgetCategory.UI_UX)?.amount ||
                0;
            const ui_ux_hours =
                budget.find((x) => x.name === BudgetCategory.UI_UX)?.hours || 0;
            const combined_qa_ux_ui_budget = qa_budget + ui_ux_budget;
            const combined_qa_ux_ui_hours = qa_hours + ui_ux_hours;
            other.push(combined_qa_ux_ui_budget);
            otherHours.push(combined_qa_ux_ui_hours);
            labels.push(date);
            totalBudget.push(
                `${isTime ? '' : '$'}${
                    aws_budget +
                    core_team_budget +
                    tech_leads_budget +
                    project_manager_budget +
                    contributors_budget +
                    qa_budget +
                    ui_ux_budget
                }${isTime ? 'h' : ''}`
            );
        });
    return {
        labels,
        totalBudget,
        datasets: [
            {
                label: 'Tech leads & PMs',
                data: techLeads,
                tooltips: techLeadHours,
                isTime,
                backgroundColor: theme.color.lightningYellow,
                boxColor: theme.color.lightningYellow,
                order: 2
            },
            {
                label: 'Contributors',
                data: _contributors,
                tooltips: _contributorHours,
                isTime,
                backgroundColor: theme.color.lightningYellowRgba,
                boxColor: theme.color.lightningYellowRgba,
                order: 2
            },
            {
                label: 'Core team',
                data: coreTeam,
                tooltips: coreTeamHours,
                isTime,
                borderColor: theme.color.white,
                backgroundColor: (context: any) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 105.23);
                    gradient.addColorStop(0, theme.color.lightningYellowRgba6);
                    gradient.addColorStop(1, theme.color.pirateGoldRgba);
                    return gradient;
                },
                boxColor: theme.gradients.darkGold,
                order: 2
            },
            {
                label: 'AWS',
                data: _aws,
                isTime,
                backgroundColor: (context: any) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 105.23);
                    gradient.addColorStop(0, theme.color.lightDimYellowRgba);
                    gradient.addColorStop(1, theme.color.pirateGold);
                    return gradient;
                },
                boxColor: theme.gradients.darkGold,
                order: 2
            },
            {
                label: 'QA & UI/UX',
                data: other,
                tooltips: otherHours,
                isTime,
                backgroundColor: (context: any) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 110);
                    gradient.addColorStop(0, theme.color.lightDimYellowRgba);
                    gradient.addColorStop(1, theme.color.pirateGold);
                    return gradient;
                },
                boxColor: theme.gradients.darkGold,
                order: 2
            },
            {
                label: '',
                data: budgetCurve,
                isTime,
                backgroundColor: theme.color.white90,
                boxColor: '',
                type: 'line' as const,
                fill: false,
                borderColor: theme.color.white50,
                borderWidth: 2,
                tension: 0.4,
                order: 1
            }
        ]
    };
};
