import React, { FunctionComponent } from 'react';
import { Chip, FormControl } from '@mui/material';
import { Avatar, Label } from 'src/shared';
import MenuItem from '@mui/material/MenuItem';
import {
    DetailsWrapper,
    SelectWrapper,
    FlexWrapper,
    AvatarWithText,
    labelStyle,
    projectText,
    boardNameStyle,
    projectTextValue
} from '../../styled';
import { useSelector } from 'react-redux';
import {
    getAllProjectsFromState,
    getProject
} from 'src/redux/projects/selectors';
import { ReactComponent as TrelloBoardIcon } from 'src/assets/icons/trello-board.svg';
import theme from 'src/theme';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { S } from './styled';
import { IProject } from 'src/types/projects';

type Props = { handleSelectProject: (id: string) => void };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ProjectDetails: FunctionComponent<Props> = ({
    handleSelectProject
}: Props) => {
    const projects = useSelector(getAllProjectsFromState);
    const [selectedProjectId, setSelectedProjectId] = React.useState<
        string | null
    >('');
    const selectedProject = useSelector(getProject(selectedProjectId));

    return (
        <DetailsWrapper>
            <AvatarWithText>
                <Avatar
                    background={theme.color.darkBlue}
                    size="110px"
                    image=""
                />
                <FormControl sx={{ ml: 3 }} variant="standard">
                    {!selectedProjectId && (
                        <Label sxOverrides={projectText}>Choose Project</Label>
                    )}
                    <SelectWrapper
                        value={selectedProjectId}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                color: theme.color.lightningYellow
                            },
                            ...projectTextValue
                        }}
                        MenuProps={{
                            MenuListProps: {
                                sx: {
                                    backgroundColor: `${theme.color.darkBlue}`,
                                    color: `${theme.color.white70}`,
                                    boxShadow: 'none'
                                }
                            },
                            PaperProps: {
                                sx: {
                                    backgroundColor: `${theme.color.darkBlue}`,
                                    marginTop: '10px'
                                }
                            }
                        }}
                        onChange={(e) => {
                            setSelectedProjectId(e.target.value as string);
                            handleSelectProject(e.target.value as string);
                        }}
                        disableUnderline
                    >
                        {projects.map((el: IProject) => {
                            return (
                                <MenuItem key={el.id} value={el.id}>
                                    {el.projectName}
                                </MenuItem>
                            );
                        })}
                    </SelectWrapper>
                    {selectedProjectId && (
                        <Label
                            sxOverrides={labelStyle}
                        >{`project id: ${selectedProjectId}`}</Label>
                    )}
                </FormControl>
            </AvatarWithText>
            <FlexWrapper>
                <TrelloBoardIcon />
                <Label sxOverrides={boardNameStyle}>
                    {selectedProject?.trelloBoard || 'missing link'}
                </Label>
            </FlexWrapper>
            <Label sxOverrides={S.statusText}>Status</Label>
            <Chip label="Active" sx={S.statusChip} />
            <Label sxOverrides={S.sinceText}>
                {selectedProject?.date
                    ? `since ${selectedProject.date.slice(0, 10)}`
                    : ''}
            </Label>
        </DetailsWrapper>
    );
};
export default ProjectDetails;
