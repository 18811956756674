export interface Quest {
    Project: string;
    'Time created': Date;
    'Quest name': string;
    'Net working time': number;
    Description: string;
}

export type SuccessCriteria = {
    title: string;
    description: string;
    explanation?: string | null;
    checked?: boolean;
};

export const successCriteriaList: SuccessCriteria[] = [
    {
        title: 'Responsive Design',
        description:
            'Ensure the website or application looks and works well across various devices and screen sizes.'
    },
    {
        title: 'Cross-browser Compatibility',
        description:
            'Ensure the website or application functions correctly on different desktop & mobile web browsers.'
    },
    {
        title: 'Data integrity',
        description: 'Verify data accuracy and consistency.'
    },
    {
        title: 'Performance Optimization',
        description: 'Aim for fast loading times and smooth performance.'
    },
    {
        title: 'Accessibility',
        description:
            'Ensure the website or app is accessible to users with disabilities.'
    },
    {
        title: 'Security Measures',
        description: 'Implement custom security measures.'
    },
    {
        title: 'Custom Documentation',
        description: 'Provide additional documentation for the codebase.'
    }
];
